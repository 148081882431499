<template>
  <div id="order-view-actions-spec-awaiting-customer-validation">
    <div v-if="isQuoteOwner">
      <OrderViewActionEdit v-if="!quoteEditable" :disabled="disabled" :btn-text="$t('action.edit_quotation')" />
      <OrderViewActionSaveAndSend v-if="quoteEditable" :disabled="disabled" />
      <OrderViewActionCancelEdition v-if="quoteEditable" :disabled="disabled" />
      <OrderViewActionCancelOrRefuse
        v-if="!quoteEditable && $can('TRIP_ACCOUNTING_DELETE')"
        :disabled="disabled"
        btn-icon="XCircleIcon"
        :btn-text="$t('action.cancel_quotation')"
        :refuse-title="$t('action.cancel_quotation')"
      />
    </div>
    <div v-else>
      <OrderViewActionAccept />
      <OrderViewActionCancelOrRefuse v-if="$can('TRIP_ACCOUNTING_DELETE')" :disabled="disabled" btn-icon="MinusCircleIcon" :btn-text="$t('action.decline')" :refuse-title="$t('action.decline')" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionsSpecAwaitingCustomerValidation',

  components: {
    OrderViewActionAccept: () => import('@/views/order/OrderViewActionAccept.vue'),
    OrderViewActionCancelOrRefuse: () => import('@/views/order/OrderViewActionCancelOrRefuse.vue'),
    OrderViewActionSaveAndSend: () => import('@/views/order/OrderViewActionSaveAndSend.vue'),
    OrderViewActionEdit: () => import('@/views/order/OrderViewActionEdit.vue'),
    OrderViewActionCancelEdition: () => import('@/views/order/OrderViewActionCancelEdition.vue'),
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['isQuoteOwner', 'quoteEditable']),
  },
}
</script>
